import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import NavBar from '../NavBar';
import Footer from '../Footer';
import LoadingContainer from '../LoadingContainer';
import ErrorContainer from '../ErrorContainer';

const GET_USERS = gql`
  {
    users {
      id
      username
      email
      firstName
      lastName
      role
      createdAt
      updatedAt
      clinicianGroup {
        id
        name
        shortName
      }
    }
  }
`;

const Users = () => (
  <div className="Users">
    <NavBar />
    <div className="section">
      <div className="container">
        <h4 className="title is-4">Users</h4>
        <div className="box">
          <Link to="/admin/users/create" className="button is-rounded">
            Create User
          </Link>{' '}
        </div>
        <div className="box">
          <Query query={GET_USERS}>
            {({ loading, error, data }) => {
              if (loading) return <LoadingContainer />;
              if (error) return <ErrorContainer message={error.message} />;

              return (
                <div className="box">
                  <table className="table is-striped is-hoverable is-fullwidth">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Clinician Group</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {data.users.map(user => (
                        <tr key={user.id}>
                          <td>{user.id}</td>
                          <td>{user.username}</td>
                          <td>{user.email}</td>
                          <td>
                            {user.firstName} {user.lastName}
                          </td>
                          <td>{user.role}</td>
                          <td>{user.clinicianGroup.shortName}</td>
                          <td>
                            <Link className="button is-rounded" to={`/admin/user/${user.id}`}>
                              View
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              );
            }}
          </Query>
        </div>
      </div>
    </div>
    <Footer />
  </div>
);

export default Users;
