import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const token = localStorage.getItem('token');
      const currentUser = jwtDecode(token);
      const { role } = currentUser;
      if (role === 'admin') {
        return <Component {...props} />;
      }
      return <Redirect to="/" />;
    }}
  />
);

export default AdminRoute;
