import React from 'react';
import moment from 'moment';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import NavBar from '../NavBar';
import Footer from '../Footer';
import LoadingContainer from '../LoadingContainer';
import ErrorContainer from '../ErrorContainer';

const GET_CLINICIAN_GROUPS = gql`
  {
    clinicianGroups {
      id
      name
      shortName
      contactEmail
      contactPhone
      createdAt
      updatedAt
    }
  }
`;

const ClinicianGroups = () => (
  <div className="Clinician-Groups">
    <NavBar />
    <div className="section">
      <div className="container">
        <h4 className="title is-4">Clinician Groups</h4>
        <div className="box">
          <Link to="/admin/clinician_groups/create" className="button is-rounded">
            Create clinician group
          </Link>{' '}
        </div>
        <div className="box">
          <Query query={GET_CLINICIAN_GROUPS}>
            {({ loading, error, data }) => {
              if (loading) return <LoadingContainer />;
              if (error) return <ErrorContainer message={error.message} />;

              return (
                <div className="box">
                  <table className="table is-striped is-hoverable is-fullwidth">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Short name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Created at</th>
                        <th>Updated at</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.clinicianGroups.map(group => (
                        <tr key={group.id}>
                          <td>{group.id}</td>
                          <td>{group.name}</td>
                          <td>{group.shortName}</td>
                          <td>{group.contactEmail}</td>
                          <td>{group.contactPhone}</td>
                          <td>{moment(group.createdAt).format('MMM D YYYY')}</td>
                          <td>{moment(group.updatedAt).format('MMM D YYYY')}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              );
            }}
          </Query>
        </div>
      </div>
    </div>
    <Footer />
  </div>
);

export default ClinicianGroups;
