import React from 'react';

const ErrorContainer = ({ message }) => (
  <div className="section">
    <div className="container">
      <article className="message is-danger">
        <div className="message-body">{message.replace('GraphQL error: ', '')}</div>
      </article>
    </div>
  </div>
);

export default ErrorContainer;
