import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { ApolloConsumer } from 'react-apollo';
import moment from 'moment';
import styled from 'styled-components';
import ErrorBox from './ErrorBox';

const SearchResults = styled.div`
  margin-top: 20px;
`;

const GET_PATIENTS_FOR_REFERENCE_ID = gql`
  query PatientsForReferenceId($referenceId: String!) {
    patientsForReferenceId(referenceId: $referenceId) {
      id
      pin
      referenceId
      createdAt
    }
  }
`;

class PatientSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientSearchReferenceId: '',
      patientResults: [],
      errors: [],
    };
  }

  onPatientSearchChange(e) {
    this.setState({ patientSearchReferenceId: e.target.value });
  }

  onPatientSearch(data) {
    this.setState({ patientResults: data.patientsForReferenceId });
  }

  renderPatientSearchResults() {
    if (this.state.patientResults.length === 0) {
      return <React.Fragment />;
    }

    return (
      <SearchResults>
        <table className="table is-striped is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th>Subject code</th>
              <th>PIN</th>
              <th>Created at</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.state.patientResults.map(patient => (
              <tr key={patient.id}>
                <td>{patient.referenceId}</td>
                <td>{patient.pin}</td>
                <td>{moment(patient.createdAt).format('MMM DD YYYY')}</td>
                <td>
                  <Link className="button" to={`/patients/${patient.id}`}>
                    View Patient
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </SearchResults>
    );
  }

  render() {
    return (
      <div className="Patient-Search box">
        <ErrorBox errors={this.state.errors} />
        <h5 className="title is-5">Search for patient by subject code</h5>

        <ApolloConsumer>
          {client => (
            <form
              onSubmit={async e => {
                e.preventDefault();
                try {
                  const { data } = await client.query({
                    query: GET_PATIENTS_FOR_REFERENCE_ID,
                    variables: { referenceId: this.state.patientSearchReferenceId },
                  });
                  this.onPatientSearch(data);
                } catch (error) {
                  this.setState({ errors: [error] });
                }
              }}
            >
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Subject code</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={this.state.patientSearchReferenceId}
                        onChange={e => this.onPatientSearchChange(e)}
                        placeholder="Enter the patient's subject code"
                      />
                    </div>
                  </div>
                  <div className="field is-narrow">
                    <div className="control">
                      <button className="button is-rounded" type="submit">
                        Search
                      </button>
                    </div>
                    <div className="field is-narrow" />
                  </div>
                </div>
              </div>
            </form>
          )}
        </ApolloConsumer>
        {this.renderPatientSearchResults()}
      </div>
    );
  }
}

export default PatientSearch;
