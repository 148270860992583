/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import jwtDecode from 'jwt-decode';

import NavBar from '../NavBar';
import Footer from '../Footer';
import PatientInfo from './PatientInfo';
import PatientTests from './PatientTests';
import PatientSymptoms from './PatientSymptoms';
import LoadingContainer from '../LoadingContainer';
import ErrorContainer from '../ErrorContainer';

const GET_PATIENT = gql`
  query Patient($id: ID!) {
    patient(id: $id) {
      id
      pin
      createdAt
      referenceId
      tests {
        id
        createdAt
        endedAt
        encryptionKey
        folderPath
        entries {
          id
          data
          createdAt
        }
      }
      symptoms {
        id
        description
        createdAt
      }
    }
  }
`;

class Patient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      showSymptoms: false,
    };
  }
  componentDidMount() {
    const token = localStorage.getItem('token');
    const currentUser = jwtDecode(token);
    this.setState({ currentUser });
  }

  render() {
    if (!this.state.currentUser) {
      return <LoadingContainer />;
    }
    const patientId = this.props.match.params.patientId;
    return (
      <div className="Patient">
        <NavBar />
        <Query query={GET_PATIENT} variables={{ id: patientId }}>
          {({ loading, error, data }) => {
            if (loading) return <LoadingContainer />;
            if (error) return <ErrorContainer message={error.message} />;

            const { patient } = data;
            const { symptoms, tests } = patient;

            return (
              <div className="section">
                <div className="container">
                  <h3 className="title is-3">Patient Summary</h3>
                  <PatientInfo patient={patient} />
                  <div className="box">
                    <div className="tabs is-centered is-medium">
                      <ul>
                        <li className={this.state.showSymptoms ? '' : 'is-active'}>
                          <a
                            href="#"
                            onClick={e => {
                              e.preventDefault();
                              this.setState({ showSymptoms: false });
                            }}
                          >
                            Recordings ({tests.length})
                          </a>
                        </li>
                        <li className={this.state.showSymptoms ? 'is-active' : ''}>
                          <a
                            href="#"
                            onClick={e => {
                              e.preventDefault();
                              this.setState({ showSymptoms: true });
                            }}
                          >
                            Symptoms ({symptoms.length})
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className={this.state.showSymptoms ? 'is-hidden' : ''}>
                      <h3 className="title is-3">Recordings</h3>
                      {tests.length > 0 ? (
                        <PatientTests
                          tests={tests}
                          showTestButton={this.state.currentUser.role === 'admin'}
                        />
                      ) : (
                        <p>No recordings.</p>
                      )}
                    </div>
                    <div className={this.state.showSymptoms ? '' : 'is-hidden'}>
                      <h3 className="title is-3">Symptoms</h3>
                      {symptoms.length > 0 ? (
                        <PatientSymptoms symptoms={symptoms} />
                      ) : (
                        <p>No symptoms logged.</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        </Query>
        <Footer />
      </div>
    );
  }
}

export default Patient;
