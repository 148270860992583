import React from 'react';

const LoadingContainer = () => (
  <div className="section">
    <div className="container">
      Loading... <i className="fas fa-spinner fa-spin" />
    </div>
  </div>
);

export default LoadingContainer;
