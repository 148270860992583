import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import NavBar from '../NavBar';
import Footer from '../Footer';

const SET_PASSWORD = gql`
  mutation SetPassword($passwordResetToken: String!, $password: String!) {
    setPassword(passwordResetToken: $passwordResetToken, password: $password) {
      token
    }
  }
`;

class SetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      successes: [],
      passwordSetSuccess: false,
    };
  }

  onSetPasswordSuccess(data) {
    const { token } = data.setPassword;
    localStorage.setItem('token', token);
    this.setState({ passwordSetSuccess: true });
    setTimeout(function() {
      window.location = '/';
    }, 4000);
  }

  onSetPasswordError(error) {
    let errors = this.state.errors;
    errors.push(error);
    this.setState({ errors });
  }

  renderErrors() {
    if (this.state.errors.length > 0) {
      return (
        <article className="message is-danger">
          <div className="message-body">{this.state.errors.map(error => error.message)}</div>
        </article>
      );
    }

    return <React.Fragment />;
  }

  render() {
    const { passwordResetToken } = this.props.match.params;

    if (this.state.passwordSetSuccess) {
      return (
        <div className="SetPassword">
          <NavBar />
          <div className="section">
            <div className="container">
              <div className="box">
                Password successfully set. Redirecting to clinician dashboard...{' '}
                <i className="fas fa-spinner fa-spin" />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      );
    }

    return (
      <div className="SetPassword">
        <NavBar />
        <div className="section">
          <div className="container">
            <h3 className="title is-3">Set Password</h3>
            {this.renderErrors()}
            <div className="box">
              <Mutation
                mutation={SET_PASSWORD}
                onCompleted={data => this.onSetPasswordSuccess(data)}
                onError={error => this.onSetPasswordError(error)}
              >
                {(setPassword, { data }) => (
                  <Formik
                    initialValues={{
                      password: '',
                      passwordConfirmation: '',
                    }}
                    validate={values => {
                      let errors = {};
                      if (!values.password || values.password === '') {
                        errors.password = 'Password is required.';
                      }

                      if (!values.passwordConfirmation || values.passwordConfirmation === '') {
                        errors.email = 'Password confirmation is required.';
                      }

                      console.log(values.password.length);

                      if (values.password.length < 8) {
                        errors.password = 'Password must be at least 8 characters.';
                      }

                      if (!(values.password === values.passwordConfirmation)) {
                        errors.passwordConfirmation = 'Passwords do not match.';
                      }

                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      this.setState({ errors: [], successes: [] });

                      const { password } = values;

                      setPassword({
                        variables: {
                          password,
                          passwordResetToken,
                        },
                      });

                      resetForm();
                      setSubmitting(false);
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="field">
                          <label className="label">Password</label>
                          <Field className="input" type="password" name="password" />
                          <ErrorMessage name="password" component="p" className="help is-danger" />
                        </div>
                        <div className="field">
                          <label className="label">Confirm password</label>
                          <Field className="input" type="password" name="passwordConfirmation" />
                          <ErrorMessage
                            name="passwordConfirmation"
                            component="p"
                            className="help is-danger"
                          />
                        </div>
                        <div className="field">
                          <div className="control">
                            <button
                              className="button is-primary"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              Set password
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </Mutation>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default SetPassword;
