/* eslint-disable react/jsx-no-comment-textnodes */
import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import moment from 'moment';
import NavBar from '../NavBar';
import Footer from '../Footer';
import LoadingContainer from '../LoadingContainer';
import ErrorContainer from '../ErrorContainer';

const GET_TEST = gql`
  query Test($id: ID!) {
    test(id: $id) {
      id
      createdAt
      endedAt
      encryptionKey
      folderPath
      bucket
      patient {
        id
        pin
        createdAt
        referenceId
      }
      entries {
        id
        data
      }
    }
  }
`;

class Test extends Component {
  render() {
    const testId = this.props.match.params.testId;
    return (
      <div className="Test">
        <NavBar />
        <Query query={GET_TEST} variables={{ id: testId }}>
          {({ loading, error, data }) => {
            if (loading) return <LoadingContainer />;
            if (error) return <ErrorContainer message={error.message} />;

            const { test } = data;
            const { patient } = test;
            const bucket = `${test.bucket}/${patient.pin}/${test.folderPath}`;
            const gcpFilesLocation = `https://console.cloud.google.com/storage/browser/${bucket}`;

            return (
              <div>
                <div className="section">
                  <div className="container">
                    <h1 className="title is-1">Test Summary</h1>
                    <div className="box">
                      <table className="table">
                        <tbody>
                          <tr>
                            <td>Test ID</td>
                            <td>{test.id}</td>
                          </tr>
                          <tr>
                            <td>Subject code</td>
                            <td>
                              <code className="has-text-black">{patient.referenceId}</code>
                            </td>
                          </tr>
                          <tr>
                            <td>Patient PIN</td>
                            <td>
                              <code className="has-text-black">{patient.pin}</code>
                            </td>
                          </tr>

                          <tr>
                            <td>Test started</td>
                            <td>{moment(test.createdAt).format('lll')}</td>
                          </tr>
                          <tr>
                            <td>Test ended</td>
                            <td>{test.endedAt ? moment(test.endedAt).format('lll') : 'N/A'}</td>
                          </tr>
                          <tr>
                            <td>Entries</td>
                            <td>{test.entries.length}</td>
                          </tr>
                          <tr>
                            <td>Folder path</td>
                            <td>
                              <code className="has-text-black">{test.folderPath}</code>
                            </td>
                          </tr>
                          <tr>
                            <td>Bucket</td>
                            <td>
                              <code className="has-text-black">{test.bucket}</code>
                            </td>
                          </tr>
                          <tr>
                            <td>Encryption key</td>
                            <td>
                              <code className="has-text-black">{test.encryptionKey}</code>
                            </td>
                          </tr>
                          <tr>
                            <td>Files location</td>
                            <td>
                              <a href={gcpFilesLocation} rel="noopener noreferrer" target="_blank">
                                {gcpFilesLocation}
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>Copy command</td>
                            <td>
                              <code className="has-text-black">gsutil -m cp gs://{bucket}/* .</code>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        </Query>
        <Footer />
      </div>
    );
  }
}

export default Test;
