import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import moment from 'moment';
import { secondsFromHms, hmsFromSeconds, hmsHour, hmsMinute, hmsSecond } from '../../utils';
import Chart from './Chart';
import LoadingContainer from '../LoadingContainer';
import ErrorContainer from '../ErrorContainer';

const GET_TEST = gql`
  query Test($id: ID!) {
    test(id: $id) {
      id
      createdAt
      endedAt
      encryptionKey
      folderPath
      bucket
      patient {
        id
        pin
        createdAt
        referenceId
      }
      entries {
        id
        data
      }
    }
  }
`;

/*
  channel prefixes:
  Respiration-
  ICG1-
  ICG2-
  ECG1-
  ECG2-
  Impedance1-
  Impedance2-
  HeartSound-
  Acceleration-
 */

class Charts extends Component {
  constructor(props) {
    super(props);
    const { testId } = this.props.match.params;

    this.state = {
      testId,
      hour: 0,
      minute: 0,
      second: 0,
    };
  }

  componentDidMount() {
    this.setChartUrls();
  }

  async hourSelected(e) {
    e.preventDefault();
    const hour = e.target.value;
    await this.setState({ hour });
    this.setChartUrls();
  }

  async minuteSelected(e) {
    e.preventDefault();
    const minute = e.target.value;
    await this.setState({ minute });
    this.setChartUrls();
  }

  async secondSelected(e) {
    e.preventDefault();
    const second = e.target.value;
    await this.setState({ second });
    this.setChartUrls();
  }

  forward30Seconds() {
    const seconds = secondsFromHms(this.getHmsString());
    const jumpToSeconds = seconds + 30;
    this.setTimeFromSeconds(jumpToSeconds);
  }

  backward30Seconds() {
    const seconds = secondsFromHms(this.getHmsString());
    const jumpToSeconds = seconds - 30;
    if (jumpToSeconds >= 0) {
      this.setTimeFromSeconds(jumpToSeconds);
    }
  }

  setTimeFromSeconds(seconds) {
    const hms = hmsFromSeconds(seconds);
    this.setTimeFromHms(hms);
  }

  async setTimeFromHms(hms) {
    const hour = parseInt(hmsHour(hms), 10);
    const minute = parseInt(hmsMinute(hms), 10);
    const second = parseInt(hmsSecond(hms), 10);
    await this.setState({ hour, minute, second });
    this.setChartUrls();
  }

  getHmsString() {
    return `${this.state.hour.toString().padStart(2, '0')}:${this.state.minute
      .toString()
      .padStart(2, '0')}:${this.state.second.toString().padStart(2, '0')}`;
  }

  setChartUrls() {
    const seconds = secondsFromHms(this.getHmsString());
    const { testId } = this.state;
    const bucketUrl = 'https://storage.googleapis.com/ss-staging-plots';
    const chartUrl = `${bucketUrl}/${testId}/${seconds}secs.svg`;
    const ecg1ChartUrl = `${bucketUrl}/${testId}/channels/ECG1-${seconds}secs.svg`;
    const ecg2ChartUrl = `${bucketUrl}/${testId}/channels/ECG2-${seconds}secs.svg`;
    const icg1ChartUrl = `${bucketUrl}/${testId}/channels/ICG1-${seconds}secs.svg`;
    const icg2ChartUrl = `${bucketUrl}/${testId}/channels/ICG2-${seconds}secs.svg`;
    const impedance1ChartUrl = `${bucketUrl}/${testId}/channels/Impedance1-${seconds}secs.svg`;
    const impedance2ChartUrl = `${bucketUrl}/${testId}/channels/Impedance2-${seconds}secs.svg`;
    const respirationChartUrl = `${bucketUrl}/${testId}/channels/Respiration-${seconds}secs.svg`;
    const heartSoundChartUrl = `${bucketUrl}/${testId}/channels/HeartSound-${seconds}secs.svg`;
    const accelerationChartUrl = `${bucketUrl}/${testId}/channels/Acceleration-${seconds}secs.svg`;

    this.setState({
      chartUrl,
      ecg1ChartUrl,
      ecg2ChartUrl,
      icg1ChartUrl,
      icg2ChartUrl,
      impedance1ChartUrl,
      impedance2ChartUrl,
      heartSoundChartUrl,
      accelerationChartUrl,
      respirationChartUrl,
    });
  }

  render() {
    const {
      testId,
      chartUrl,
      ecg1ChartUrl,
      ecg2ChartUrl,
      icg1ChartUrl,
      icg2ChartUrl,
      impedance1ChartUrl,
      impedance2ChartUrl,
      respirationChartUrl,
      heartSoundChartUrl,
      accelerationChartUrl,
    } = this.state;

    return (
      <Query query={GET_TEST} variables={{ id: testId }}>
        {({ loading, error, data }) => {
          if (loading) return <LoadingContainer />;
          if (error) return <ErrorContainer message={error.message} />;
          const { test } = data;
          const { patient } = test;

          return (
            <div className="Charts">
              <div className="section">
                <div className="box is-size-5">
                  <div>
                    Subject code:{' '}
                    <span className="has-text-weight-semibold">{patient.referenceId}</span>
                  </div>
                  <div>
                    Recording started:{' '}
                    <span className="has-text-weight-semibold">
                      {moment(test.createdAt).format('lll')}
                    </span>
                  </div>
                </div>

                <div className="charts-header box">
                  <div className="level">
                    <div className="level-left">
                      <div className="level-item has-text-weight-semibold">
                        T: {this.getHmsString()}
                      </div>
                      <div className="level-item">
                        Hour:&nbsp;
                        <div className="select">
                          <select onChange={e => this.hourSelected(e)} value={this.state.hour}>
                            {Array.from({ length: 12 }, (_, i) => i).map(num => (
                              <option key={num}>{num}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="level-item">
                        Minute:&nbsp;
                        <div className="select">
                          <select onChange={e => this.minuteSelected(e)} value={this.state.minute}>
                            {Array.from({ length: 60 }, (_, i) => i).map(num => (
                              <option key={num}>{num}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="level-item">
                        Second:&nbsp;
                        <div className="select">
                          <select onChange={e => this.secondSelected(e)} value={this.state.second}>
                            <option>0</option>
                            <option>30</option>
                          </select>
                        </div>
                      </div>
                      <div className="level-item">
                        <button className="button" onClick={e => this.backward30Seconds()}>
                          <i className="fas fa-arrow-left" />
                          &nbsp;Back 30s
                        </button>
                      </div>
                      <div className="level-item">
                        <button className="button" onClick={e => this.forward30Seconds()}>
                          Forward 30s&nbsp; <i className="fas fa-arrow-right" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="chart-channels">
                  <Chart title="All Channels" chartImageUrl={chartUrl} />
                  <Chart title="ECG 1" chartImageUrl={ecg1ChartUrl} />
                  <Chart title="Impedance 1" chartImageUrl={impedance1ChartUrl} />
                  <Chart title="ICG 1" chartImageUrl={icg1ChartUrl} />
                  <Chart title="Heart Sound" chartImageUrl={heartSoundChartUrl} />
                  <Chart title="Respiration" chartImageUrl={respirationChartUrl} />
                  <Chart title="ECG 2" chartImageUrl={ecg2ChartUrl} />
                  <Chart title="Impedance 2" chartImageUrl={impedance2ChartUrl} />
                  <Chart title="ICG 2" chartImageUrl={icg2ChartUrl} />
                  <Chart title="XYZ Acceleration" chartImageUrl={accelerationChartUrl} />
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default Charts;
