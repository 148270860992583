import React, { Component } from 'react';

class SignOut extends Component {
  componentDidMount() {
    localStorage.removeItem('token');
    window.location = '/';
  }

  render() {
    return <div>Signing out...</div>;
  }
}

export default SignOut;
