import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import logo from '../images/nanowear-icon.png';
class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = { currentUser: null };
  }

  async componentDidMount() {
    const token = await localStorage.getItem('token');
    if (token) {
      const currentUser = jwtDecode(token);
      this.setState({ currentUser });
    }
  }

  renderAuthLinks() {
    const { currentUser } = this.state;
    if (currentUser) {
      return (
        <React.Fragment>
          <div className="navbar-item">
            <div className="buttons">
              <Link to="/signout" className="button is-rounded is-light">
                Sign out
              </Link>
            </div>
          </div>
        </React.Fragment>
      );
    }

    return <React.Fragment />;
  }

  renderAdminLink() {
    const { currentUser } = this.state;
    if (!currentUser) return <React.Fragment />;
    if (currentUser.role === 'admin') {
      return (
        <Link to="/admin" className="navbar-item">
          Admin
        </Link>
      );
    }
  }

  render() {
    return (
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item">
              <img className="navbar-logo" src={logo} alt="Nanowear" />
              SimpleSense Manager
            </Link>
          </div>
          <div className="navbar-menu is-active">
            <div className="navbar-start">
              <Link to="/clinician" className="navbar-item">
                Clinician Dashboard
              </Link>
              {this.renderAdminLink()}
            </div>
          </div>

          <div className="navbar-menu is-active">
            <div className="navbar-end">{this.renderAuthLinks()}</div>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavBar;
