import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import NavBar from '../NavBar';
import Footer from '../Footer';
import LoadingContainer from '../LoadingContainer';
import ErrorContainer from '../ErrorContainer';

const GET_USER = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      username
      email
      firstName
      lastName
      role
      createdAt
      passwordResetToken
      clinicianGroup {
        id
        name
        shortName
      }
    }
  }
`;

const HOST = process.env.REACT_APP_HOST;
class User extends Component {
  render() {
    const userId = this.props.match.params.userId;
    return (
      <div className="User">
        <NavBar />
        <Query query={GET_USER} variables={{ id: userId }}>
          {({ loading, error, data }) => {
            if (loading) return <LoadingContainer />;
            if (error) return <ErrorContainer message={error.message} />;

            const { user } = data;

            return (
              <div>
                <div className="section">
                  <div className="container">
                    <h1 className="title is-1">{user.email}</h1>
                    <div className="box">
                      <table className="table">
                        <tbody>
                          <tr>
                            <th>User ID</th>
                            <td>{user.id}</td>
                          </tr>
                          <tr>
                            <th>Username</th>
                            <td>{user.username}</td>
                          </tr>
                          <tr>
                            <th>Email</th>
                            <td>{user.email}</td>
                          </tr>
                          <tr>
                            <th>Name</th>
                            <td>
                              {user.firstName} {user.lastName}
                            </td>
                          </tr>
                          <tr>
                            <th>Clinician group</th>
                            <td>{user.clinicianGroup.name}</td>
                          </tr>
                          <tr>
                            <th>Role</th>
                            <td>{user.role}</td>
                          </tr>
                          <tr>
                            <th>Set password link</th>
                            <td>
                              <code className="has-text-black">
                                https://{HOST}/set_password/
                                {user.passwordResetToken}
                              </code>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        </Query>
        <Footer />
      </div>
    );
  }
}

export default User;
