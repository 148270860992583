import React, { Component } from 'react';
import { Mutation, Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import NavBar from '../NavBar';
import Footer from '../Footer';

const CREATE_USER = gql`
  mutation CreateUser(
    $username: String!
    $email: String!
    $firstName: String
    $lastName: String
    $clinicianGroupId: ID!
    $password: String!
    $role: String!
  ) {
    createUser(
      username: $username
      email: $email
      firstName: $firstName
      lastName: $lastName
      clinicianGroupId: $clinicianGroupId
      role: $role
      password: $password
    ) {
      id
      username
      email
      firstName
      lastName
      role
      clinicianGroup {
        id
        name
        shortName
      }
    }
  }
`;

const GET_CLINICIAN_GROUPS = gql`
  {
    clinicianGroups {
      id
      name
      shortName
      contactEmail
      contactPhone
      createdAt
      updatedAt
    }
  }
`;

class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      successes: [],
    };
  }

  onCreateUserSuccess(data) {
    let successes = ['User created.'];
    this.setState({ successes });
  }

  onCreateUserError(error) {
    let errors = this.state.errors;
    errors.push(error);
    this.setState({ errors });
  }

  renderErrors() {
    if (this.state.errors.length > 0) {
      return (
        <article className="message is-danger">
          <div className="message-body">{this.state.errors.map(error => error.message)}</div>
        </article>
      );
    }

    return <React.Fragment />;
  }

  renderSuccesses() {
    if (this.state.successes.length > 0) {
      return (
        <article className="message is-success">
          <div className="message-body">{this.state.successes.map(success => success)}</div>
        </article>
      );
    }

    return <React.Fragment />;
  }

  render() {
    return (
      <div className="CreateUser">
        <NavBar />
        <div className="section">
          <div className="container">
            <h3 className="title is-3">Create user</h3>
            {this.renderSuccesses()}
            {this.renderErrors()}
            <div className="box">
              <Mutation
                mutation={CREATE_USER}
                onCompleted={data => this.onCreateUserSuccess(data)}
                onError={error => this.onCreateUserError(error)}
              >
                {(createUser, { data }) => (
                  <Formik
                    initialValues={{
                      username: '',
                      email: '',
                      firstName: '',
                      lastName: '',
                      role: '',
                      clinicianGroupId: null,
                    }}
                    validate={values => {
                      let errors = {};
                      if (!values.username || values.username === '') {
                        errors.username = 'Username is required';
                      }

                      if (!values.email || values.email === '') {
                        errors.email = 'Email is required';
                      }

                      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                        errors.email = 'Invalid email address.';
                      }

                      if (!values.clinicianGroupId || parseInt(values.clinicianGroupId, 10) === 0) {
                        errors.clinicianGroupId = 'Clinican group is required';
                      }

                      if (
                        !values.role ||
                        values.role === '' ||
                        (values.role !== 'admin' && values.role !== 'clinician')
                      ) {
                        errors.role = 'Role is required';
                      }

                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      this.setState({ errors: [], successes: [] });

                      const {
                        username,
                        email,
                        firstName,
                        lastName,
                        role,
                        clinicianGroupId,
                      } = values;

                      const password = 'raewonan'; // todo: replace this with a randomly generated string

                      createUser({
                        variables: {
                          username,
                          email,
                          firstName,
                          lastName,
                          role,
                          clinicianGroupId,
                          password,
                        },
                      });

                      resetForm();
                      setSubmitting(false);
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="field">
                          <label className="label">Username</label>
                          <Field className="input" type="text" name="username" />
                          <ErrorMessage name="username" component="p" className="help is-danger" />
                        </div>
                        <div className="field">
                          <label className="label">Email address</label>
                          <Field className="input" type="text" name="email" />
                          <ErrorMessage name="email" component="p" className="help is-danger" />
                        </div>
                        <div className="field">
                          <label className="label">First name</label>
                          <Field className="input" type="text" name="firstName" />
                          <ErrorMessage name="firstName" component="p" className="help is-danger" />
                        </div>
                        <div className="field">
                          <label className="label">Last name</label>
                          <Field className="input" type="text" name="lastName" />
                          <ErrorMessage name="lastName" component="p" className="help is-danger" />
                        </div>
                        <div className="field">
                          <label className="label">Clinician Group</label>
                          <div className="select">
                            <Field component="select" value={undefined} name="clinicianGroupId">
                              <option value="0">Select a clinician group</option>
                              <Query query={GET_CLINICIAN_GROUPS}>
                                {({ loading, error, data }) => {
                                  if (loading) return <option />;
                                  if (error) return <option>{error.message}</option>;
                                  return data.clinicianGroups.map(group => (
                                    <option key={group.id} value={group.id}>
                                      {group.name}
                                    </option>
                                  ));
                                }}
                              </Query>
                            </Field>
                          </div>
                          <ErrorMessage
                            name="clinicianGroupId"
                            component="p"
                            className="help is-danger"
                          />
                        </div>
                        <div className="field">
                          <label className="label">Role</label>
                          <div className="select">
                            <Field component="select" value={undefined} name="role">
                              <option value="0">Select a role</option>
                              <option value="clinician">Clinician</option>
                              <option value="admin">Admin</option>
                            </Field>
                          </div>
                          <ErrorMessage name="role" component="p" className="help is-danger" />
                        </div>
                        <div className="field">
                          <div className="control">
                            <button
                              className="button is-primary"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              Create User
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </Mutation>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default CreateUser;
