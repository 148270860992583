import React, { Component } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import NavBar from '../NavBar';

const CREATE_CLINICIAN_GROUP = gql`
  mutation CreateClinicianGroup(
    $name: String!
    $shortName: String!
    $contactEmail: String!
    $contactPhone: String!
  ) {
    createClinicianGroup(
      name: $name
      shortName: $shortName
      contactEmail: $contactEmail
      contactPhone: $contactPhone
    ) {
      id
      name
      shortName
      contactEmail
      contactPhone
    }
  }
`;

class CreateClinicianGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      successes: [],
    };
  }

  onCreateClinicianGroupError(error) {
    let errors = this.state.errors;
    errors.push(error);
    this.setState({ errors });
  }

  onCreateClinicianGroupSuccess(success) {
    let successes = ['Clinician group created.'];
    this.setState({ successes });
  }

  renderErrors() {
    if (this.state.errors.length > 0) {
      return (
        <article className="message is-danger">
          <div className="message-body">{this.state.errors.map(error => error.message)}</div>
        </article>
      );
    }

    return <React.Fragment />;
  }

  renderSuccesses() {
    if (this.state.successes.length > 0) {
      return (
        <article className="message is-success">
          <div className="message-body">{this.state.successes.map(success => success)}</div>
        </article>
      );
    }

    return <React.Fragment />;
  }

  render() {
    return (
      <div className="CreateClinicianGroup">
        <NavBar />
        <div className="section">
          <div className="container">
            {this.renderSuccesses()}
            {this.renderErrors()}
            <h3 className="title is-3">Create clinician group</h3>
            <div className="box">
              <Mutation
                mutation={CREATE_CLINICIAN_GROUP}
                onCompleted={data => this.onCreateClinicianGroupSuccess(data)}
                onError={error => this.onCreateClinicianGroupError(error)}
              >
                {(createClinicianGroup, { data }) => (
                  <Formik
                    initialValues={{ name: '', shortName: '', contactEmail: '', contactPhone: '' }}
                    validate={values => {
                      let errors = {};
                      if (!values.name || values.name === '') {
                        errors.name = 'Required';
                      }
                      if (!values.shortName) {
                        errors.shortName = 'Required';
                      }
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      const { name, shortName, contactEmail, contactPhone } = values;
                      this.setState({ errors: [], successes: [] });
                      createClinicianGroup({
                        variables: { name, shortName, contactEmail, contactPhone },
                      });
                      resetForm();
                      setSubmitting(false);
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="field">
                          <label className="label">Group name</label>
                          <Field className="input" type="text" name="name" />
                          <ErrorMessage name="name" component="p" className="help is-danger" />
                        </div>
                        <div className="field">
                          <label className="label">Group short name</label>
                          <Field className="input" type="text" name="shortName" />
                          <ErrorMessage name="shortName" component="p" className="help is-danger" />
                        </div>
                        <div className="field">
                          <label className="label">Contact email</label>
                          <Field className="input" type="email" name="contactEmail" />
                          <ErrorMessage
                            name="contactEmail"
                            component="p"
                            className="help is-danger"
                          />
                        </div>
                        <div className="field">
                          <label className="label">Contact phone</label>
                          <Field className="input" type="text" name="contactPhone" />
                          <ErrorMessage
                            name="contactPhone"
                            component="p"
                            className="help is-danger"
                          />
                        </div>
                        <div className="field">
                          <div className="control">
                            <button
                              className="button is-link"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              Create clinician group
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </Mutation>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateClinicianGroup;
