export function secondsFromHms(hms) {
  // expects format HH:MM:SS

  var a = hms.split(':'); // split it at the colons

  // minutes are worth 60 seconds. Hours are worth 60 minutes.
  var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];

  return seconds;
}

export function hmsFromSeconds(seconds) {
  return new Date(seconds * 1000).toISOString().substr(11, 8);
}

export function hmsHour(hms) {
  var a = hms.split(':'); // split it at the colons
  return a[0];
}

export function hmsMinute(hms) {
  var a = hms.split(':'); // split it at the colons
  return a[1];
}

export function hmsSecond(hms) {
  var a = hms.split(':'); // split it at the colons
  return a[2];
}
