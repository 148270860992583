import React from 'react';
import moment from 'moment';

const PatientInfo = ({ patient }) => (
  <React.Fragment>
    <div className="box">
      <h3 className="title is-3 has-text-centered">{patient.referenceId}</h3>

      <div className="level">
        <div className="level-item">
          <p>
            PIN: <strong>{patient.pin}</strong>
          </p>
        </div>
        <div className="level-item">
          <p>
            ID: <strong>{patient.id}</strong>
          </p>
        </div>
        <div className="level-item">
          <p>
            Created: <strong>{moment(patient.createdAt).format('MMM D, YYYY')}</strong>
          </p>
        </div>
      </div>
    </div>
  </React.Fragment>
);

export default PatientInfo;
