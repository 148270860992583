import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../NavBar';
import Footer from '../Footer';
import PatientSearch from '../PatientSearch';
import AdminPatients from './AdminPatients';

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
    };
  }

  renderErrors() {
    if (this.state.errors.length > 0) {
      return (
        <article className="message is-danger">
          <div className="message-body">{this.state.errors.map(error => error.message)}</div>
        </article>
      );
    }

    return <React.Fragment />;
  }

  render() {
    return (
      <div className="Admin">
        <NavBar />
        <div className="section">
          <div className="container">
            {this.renderErrors()}
            <h3 className="title is-3">SimpleSense Admin</h3>
            <div className="box">
              <Link to="/admin/users" className="button is-rounded">
                Users
              </Link>{' '}
              <Link to="/admin/clinician_groups" className="button is-rounded">
                Clinician Groups
              </Link>
            </div>
            <PatientSearch history={this.props.history} />
            <AdminPatients />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Admin;
