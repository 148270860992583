import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';

import NavBar from '../NavBar';
import Footer from '../Footer';
import ErrorBox from '../ErrorBox';

const RESET_PASSWORD_REQUEST = gql`
  mutation requestPasswordReset($email: String!) {
    requestPasswordReset(email: $email)
  }
`;

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errors: [],
      successes: [],
    };
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  onRequestPasswordResetSuccess(data) {
    this.setState({
      successes: [
        {
          message: `Password reset email sent to ${this.state.email}`,
        },
      ],
    });
  }

  onRequestPasswordResetError(error) {
    let errors = this.state.errors;
    errors.push(error);
    this.setState({ errors });
  }

  renderSuccesses() {
    if (this.state.successes.length > 0) {
      return (
        <article className="message is-success">
          <div className="message-body">{this.state.successes.map(success => success.message)}</div>
        </article>
      );
    }

    return <React.Fragment />;
  }

  render() {
    return (
      <div className="RequestPasswordReset">
        <NavBar />
        <main>
          <div className="section">
            <div className="container">
              <div className="box">
                <h1 className="title is-1">Reset Password</h1>
                <ErrorBox errors={this.state.errors} />
                {this.renderSuccesses()}
                <Mutation
                  mutation={RESET_PASSWORD_REQUEST}
                  onCompleted={data => this.onRequestPasswordResetSuccess(data)}
                  onError={error => this.onRequestPasswordResetError(error)}
                >
                  {(requestPasswordReset, { data }) => (
                    <form
                      onSubmit={e => {
                        e.preventDefault();
                        this.setState({ errors: [], successes: [] });
                        requestPasswordReset({
                          variables: { email: this.state.email },
                        });
                      }}
                    >
                      <div className="field">
                        <label className="label">Email address</label>
                        <div className="field">
                          <div className="control">
                            <input
                              type="text"
                              className="input"
                              value={this.state.email}
                              onChange={e => this.handleEmailChange(e)}
                              placeholder="Enter your email address"
                            />
                          </div>
                        </div>
                        <div className="control">
                          <button className="button is-primary is-rounded" type="submit">
                            Request password reset
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Mutation>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    );
  }
}

export default ForgotPassword;
