import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

class PatientTests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testDates: [],
      groupedTests: [],
    };
  }

  componentDidMount() {
    this.groupTestsByDate();
  }

  groupTestsByDate() {
    const { tests } = this.props;
    const earliestTest = tests.slice(-1)[0];

    // build array of days from first test to today
    // todo: handle patients who have finished 90 days of testing
    let testDates = [];
    let currentDate = moment(earliestTest.createdAt);
    const stopDate = moment(new Date());
    while (currentDate <= stopDate) {
      testDates.push(moment(currentDate.format('YYYY-MM-DD')));
      currentDate.add(1, 'days');
    }
    testDates = testDates.reverse();

    const groupedTests = {};
    testDates.forEach(date => {
      const testsForDate = [];
      tests.forEach(test => {
        const testDate = moment(test.createdAt);
        if (testDate.isSame(date, 'day')) {
          testsForDate.push(test);
        }
      });
      groupedTests[date.format('YYYY-MM-DD')] = testsForDate;
    });

    this.setState({
      testDates,
      groupedTests,
    });
  }

  renderNoDataRow(date) {
    return (
      <tr key={moment(date).format('YYYY-MM-DD')}>
        <td>{moment(date).format('ddd ll')}</td>
        <td>
          <span className="tag is-light">No recordings</span>
        </td>
        <td colSpan="7" />
      </tr>
    );
  }

  renderTestRowsForDate(date) {
    const { showTestButton } = this.props;

    return this.state.groupedTests[moment(date).format('YYYY-MM-DD')].map(test => {
      const { id, createdAt, endedAt, entries } = test;

      // todo: hack: this is hack for demonstration purposes
      // hardcoding test ids 185 and 190 to show chart button
      // as those are the ones we've generated on staging
      const testIdInt = parseInt(test.id, 10);
      const showChartsButton = testIdInt === 185 || testIdInt === 190;

      let lastEntryTime = 'N/A';
      if (entries.length > 0) {
        const lastEntry = entries.slice(-1)[0];
        lastEntryTime = moment(lastEntry.createdAt).format('h:mm a');
      }
      const elapsedTime = endedAt
        ? moment.duration(moment(createdAt).diff(endedAt)).humanize()
        : 'N/A';
      return (
        <tr key={id}>
          <td>{moment(createdAt).format('ddd ll')}</td>
          <td>
            {entries.length > 143 ? (
              <span className="tag is-success">Complete</span>
            ) : (
              <span className="tag is-warning">Incomplete</span>
            )}
          </td>
          <td>{entries.length}</td>
          <td>{moment(createdAt).format('h:mm a')}</td>
          <td>{lastEntryTime}</td>
          <td>
            {test.endedAt ? (
              <span className="tag is-success">Yes</span>
            ) : (
              <span className="tag is-danger">No</span>
            )}
          </td>
          <td>{elapsedTime}</td>
          <td>
            {showChartsButton ? (
              <a
                className="button is-small is-rounded is-pulled-right"
                href={`/tests/${id}/charts`}
                onClick={e => {
                  e.preventDefault();
                  window.open(
                    `/tests/${id}/charts`,
                    'targetWindow',
                    `
                    toolbar=no,
                    location=no,
                    status=no,
                    menubar=no,
                    scrollbars=yes,
                    resizable=yes,
                    width=1280,
                    height=960
                    `
                  );
                  return false;
                }}
              >
                <i className="fa fa-chart-line" />
              </a>
            ) : (
              <React.Fragment />
            )}
          </td>
          <td>
            {showTestButton ? (
              <Link className="button is-small is-rounded is-pulled-right" to={`/tests/${id}`}>
                <i className="fa fa-info" />
              </Link>
            ) : (
              <React.Fragment />
            )}
          </td>
        </tr>
      );
    });
  }

  render() {
    const { groupedTests, testDates } = this.state;
    return (
      <table className="table is-striped is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>Date</th>
            <th>Status</th>
            <th>Entries</th>
            <th>Start time</th>
            <th>Last entry</th>
            <th>Did stop</th>
            <th>Elapsed</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {testDates.map(testDate => {
            const formattedDate = testDate.format('YYYY-MM-DD');
            if (groupedTests[formattedDate].length > 0) {
              return this.renderTestRowsForDate(formattedDate);
            } else {
              return this.renderNoDataRow(formattedDate);
            }
          })}
        </tbody>
      </table>
    );
  }
}

export default PatientTests;
