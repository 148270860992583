import React from 'react';
import moment from 'moment';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import LoadingContainer from '../LoadingContainer';
import ErrorContainer from '../ErrorContainer';

const GET_ALL_PATIENTS = gql`
  {
    allPatients {
      id
      pin
      referenceId
      createdAt
      lastSignedInAt
      tests {
        id
        createdAt
      }
      clinicianGroup {
        id
        name
        shortName
      }
    }
  }
`;

const AdminPatients = () => (
  <div className="box">
    <h4 className="title is-4">Patients</h4>
    <Query fetchPolicy="network-only" query={GET_ALL_PATIENTS}>
      {({ loading, error, data }) => {
        if (loading) return <LoadingContainer />;
        if (error) return <ErrorContainer message={error.message} />;

        return (
          <div className="box">
            <table className="table is-striped is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th>Subject Code</th>
                  <th>Group</th>
                  <th>Enrolled</th>
                  <th>Signed in</th>
                  <th>Recordings</th>
                  <th>Last recording</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {data.allPatients.map(patient => (
                  <tr key={patient.id}>
                    <td>
                      <strong>
                        <code className="has-text-black">{patient.referenceId}</code>
                      </strong>
                    </td>
                    <td>{patient.clinicianGroup.shortName}</td>
                    <td>{moment(patient.createdAt).format('ll')}</td>
                    <td>
                      {patient.lastSignedInAt ? (
                        <span className="tag is-primary is-success">Yes</span>
                      ) : (
                        <span className="tag">No</span>
                      )}
                    </td>
                    <td>{patient.tests.length}</td>
                    <td>
                      {patient.tests.length > 0
                        ? moment(patient.tests[0].createdAt).format('ll')
                        : 'N/A'}
                    </td>
                    <td>
                      <Link className="button is-rounded" to={`/patients/${patient.id}`}>
                        View Patient
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      }}
    </Query>
  </div>
);

export default AdminPatients;
