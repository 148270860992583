import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';

import NavBar from './NavBar';
import Footer from './Footer';
import ErrorBox from './ErrorBox';

const SIGN_IN = gql`
  mutation SignIn($login: String!, $password: String!) {
    signIn(login: $login, password: $password) {
      token
    }
  }
`;

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: '',
      password: '',
      errors: [],
    };
  }

  handleLoginChange(e) {
    this.setState({ login: e.target.value });
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  onSignInSuccess(data) {
    const { token } = data.signIn;
    localStorage.setItem('token', token);
    window.location = '/';
  }

  onSignInError(error) {
    let errors = this.state.errors;
    errors.push(error);
    this.setState({ errors });
  }

  render() {
    return (
      <div className="Sign-In">
        <NavBar />
        <div className="section">
          <div className="container">
            <div className="columns">
              <div className="column is-half is-offset-one-quarter">
                <h3 className="title is-3">Sign in</h3>
                <ErrorBox errors={this.state.errors} />
                <Mutation
                  mutation={SIGN_IN}
                  onCompleted={data => this.onSignInSuccess(data)}
                  onError={error => this.onSignInError(error)}
                >
                  {(signIn, { data }) => (
                    <form
                      onSubmit={e => {
                        e.preventDefault();
                        localStorage.removeItem('token', null);
                        this.setState({ errors: [] });
                        signIn({
                          variables: { login: this.state.login, password: this.state.password },
                        });
                      }}
                    >
                      <div className="field">
                        <label className="label">Username or email address</label>
                        <div className="control">
                          <input
                            className="input"
                            type="text"
                            placeholder="Enter your username or email address"
                            value={this.state.login}
                            autoComplete="username"
                            onChange={e => this.handleLoginChange(e)}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Password</label>
                        <div className="control">
                          <input
                            className="input"
                            type="password"
                            placeholder="Enter your password"
                            value={this.state.password}
                            autoComplete="current-password"
                            onChange={e => this.handlePasswordChange(e)}
                          />
                        </div>
                        <p className="help">
                          <Link to="/forgot_password">Forgot password?</Link>
                        </p>
                      </div>
                      <div className="control">
                        <button className="button is-large is-primary" type="submit">
                          Sign in
                        </button>
                      </div>
                    </form>
                  )}
                </Mutation>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default SignIn;
