import React, { Component } from 'react';

class ErrorBox extends Component {
  render() {
    if (this.props.errors.length > 0) {
      return (
        <article className="message is-danger">
          <div className="message-body">
            {this.props.errors.map(error => error.message.replace('GraphQL error: ', ''))}
          </div>
        </article>
      );
    }

    return <React.Fragment />;
  }
}

export default ErrorBox;
