import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import NavBar from './NavBar';
import Footer from './Footer';
import PatientSearch from './PatientSearch';
import Patients from './Patients';
class Clinician extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
    };
  }

  renderErrors() {
    if (this.state.errors.length > 0) {
      return (
        <article className="message is-danger">
          <div className="message-body">{this.state.errors.map(error => error.message)}</div>
        </article>
      );
    }

    return <React.Fragment />;
  }

  render() {
    return (
      <div className="Clinician">
        <NavBar />
        <main className="Site-content">
          <div className="section">
            <div className="container">
              {this.renderErrors()}
              <h3 className="title is-3">SimpleSense Clinician Dashboard</h3>
              <div className="box">
                <Link to="/patients/create" className="button is-rounded is-primary">
                  Enroll patient
                </Link>
              </div>
              <PatientSearch history={this.props.history} />
              <Patients />
            </div>
          </div>
        </main>
        <Footer />
      </div>
    );
  }
}

export default Clinician;
