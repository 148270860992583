import React from 'react';
import moment from 'moment';

const PatientSymptoms = ({ symptoms }) => (
  <table className="table is-striped is-hoverable">
    <thead>
      <tr>
        <th>Description</th>
        <th>Logged at</th>
      </tr>
    </thead>
    <tbody>
      {symptoms.map(symptom => (
        <tr key={symptom.id}>
          <td>{symptom.description}</td>
          <td>{moment(symptom.createdAt).format('ddd ll HH:mm a')}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default PatientSymptoms;
