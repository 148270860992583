import React from 'react';
import NavBar from './NavBar';
import Footer from './Footer';

const NotFound = () => (
  <div className="NotFound">
    <NavBar />
    <main>
      <div className="section">
        <div className="container">
          <div className="box">
            <p>Page not found.</p>
          </div>
        </div>
      </div>
    </main>
    <Footer />
  </div>
);

export default NotFound;
