import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Link } from 'react-router-dom';

import NavBar from './NavBar';
import Footer from './Footer';
import ErrorBox from './ErrorBox';

const CREATE_PATIENT = gql`
  mutation CreatePatient($referenceId: String!) {
    createPatient(referenceId: $referenceId) {
      id
      pin
      referenceId
    }
  }
`;
class CreatePatient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patient: null,
      referenceId: '',
      errors: [],
    };
  }

  onCreatePatientSuccess(data) {
    const patient = data.createPatient;
    this.setState({
      patient: { id: patient.id, pin: patient.pin, referenceId: patient.referenceId },
    });
  }

  onCreatePatientError(error) {
    let errors = this.state.errors;
    errors.push(error);
    this.setState({ errors });
  }

  handleReferenceIDChange(e) {
    this.setState({ referenceId: e.target.value });
  }

  renderNewPatientForm() {
    return (
      <React.Fragment>
        <Mutation
          mutation={CREATE_PATIENT}
          onCompleted={data => this.onCreatePatientSuccess(data)}
          onError={error => this.onCreatePatientError(error)}
        >
          {(createPatient, { data }) => (
            <form
              onSubmit={e => {
                e.preventDefault();
                if (this.state.referenceId === '') {
                  this.setState({
                    errors: [{ message: 'You must enter a subject code to enroll a patient.' }],
                  });
                  return;
                }
                if (
                  window.confirm(
                    `Enroll new patient with subject code '${this.state.referenceId}'?`
                  )
                ) {
                  this.setState({ errors: [], referenceId: '' });
                  createPatient({
                    variables: {
                      referenceId: this.state.referenceId,
                    },
                  });
                }
              }}
            >
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Subject code</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control is-expanded">
                      <input
                        className="input"
                        type="text"
                        placeholder="Enter the patient's subject code"
                        value={this.state.referenceId}
                        onChange={e => this.handleReferenceIDChange(e)}
                      />
                    </div>
                  </div>
                  <div className="control">
                    <button className="button is-rounded is-primary" type="submit">
                      Enroll new patient
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Mutation>
      </React.Fragment>
    );
  }

  renderNewPatientInfo() {
    const { patient } = this.state;
    return (
      <div className="has-text-centered">
        <h4 className="title is-4">
          Patient with subject code <code className="has-text-black">{patient.referenceId}</code>{' '}
          enrolled with PIN:
        </h4>
        <h1 className="title is-1">
          <code className="has-text-black">{patient.pin}</code>
        </h1>
        <div>
          <Link to="/">Return to dashboard</Link>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Create-Patient">
        <NavBar />
        <div className="section">
          <div className="container">
            <h3 className="title is-3">Enroll a new patient</h3>
            <ErrorBox errors={this.state.errors} />
            <div className="box">
              {this.state.patient ? this.renderNewPatientInfo() : this.renderNewPatientForm()}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default CreatePatient;
