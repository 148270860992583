import React from 'react';

const Chart = ({ title, chartImageUrl }) => (
  <div className="Chart box">
    <div className="has-text-centered	is-size-4 has-text-weight-semibold">{title}</div>
    <img className="plot-image" alt="Plot" src={chartImageUrl} />
  </div>
);

export default Chart;
