import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
class Home extends Component {
  render() {
    const token = localStorage.getItem('token');
    if (token) {
      const currentUser = jwtDecode(token);
      const { role } = currentUser;

      if (role === 'admin') {
        return <Redirect to="/admin" />;
      } else {
        return <Redirect to="/clinician" />;
      }
    }

    return (
      <div className="Home">
        <section className="hero is-bold is-dark is-fullheight Home-hero-image">
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1 className="title is-1">Nanowear</h1>
              <h2 className="subtitle is-2">SimpleSense Manager</h2>
              <div className="buttons is-centered">
                <Link className="button is-rounded is-primary is-large is-outlined" to="/signin">
                  Sign in
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;
