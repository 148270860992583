import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import AdminRoute from './AdminRoute';
import Home from './components/Home';
import Admin from './components/admin/Admin';
import Clinician from './components/Clinician';
import SignIn from './components/SignIn';
import SignOut from './components/SignOut';
import Patient from './components/patient/Patient';
import Test from './components/test/Test';
import CreatePatient from './components/CreatePatient';
import Charts from './components/charts/Charts';
import CreateClinicianGroup from './components/admin/CreateClinicianGroup';
import ClinicianGroups from './components/admin/ClinicianGroups';
import Users from './components/admin/Users';
import CreateUser from './components/admin/CreateUser';
import User from './components/admin/User';
import ForgotPassword from './components/password/ForgotPassword';
import SetPassword from './components/password/SetPassword';
import NotFound from './components/NotFound';
class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/signin" exact component={SignIn} />
          <Route path="/signout" exact component={SignOut} />
          <Route path="/forgot_password" exact component={ForgotPassword} />
          <Route path="/set_password/:passwordResetToken" exact component={SetPassword} />
          <ProtectedRoute path="/clinician" exact component={Clinician} />
          <ProtectedRoute path="/patients/create" exact component={CreatePatient} />
          <ProtectedRoute path="/patients/:patientId" exact component={Patient} />
          <ProtectedRoute path="/tests/:testId/charts" exact component={Charts} />
          <ProtectedRoute path="/tests/:testId" exact component={Test} />
          <AdminRoute path="/admin" exact component={Admin} />
          <AdminRoute
            path="/admin/clinician_groups/create"
            exact
            component={CreateClinicianGroup}
          />
          <AdminRoute path="/admin/clinician_groups" exact component={ClinicianGroups} />
          <AdminRoute path="/admin/users/create" exact component={CreateUser} />
          <AdminRoute path="/admin/users" exact component={Users} />
          <AdminRoute path="/admin/user/:userId" exact component={User} />
          <Route path="/" exact component={Home} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default App;
