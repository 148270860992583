import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="has-text-centered">
          <p>&copy; 2019 Nanowear, Inc. All Rights Reserved.</p>
        </div>
      </footer>
    );
  }
}

export default Footer;
